@import '@styles/_mixin.scss';

.titlebox {
  background-color: #fff;
  padding: 20px 20px;
  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@include responsive_mobile {
  .titlebox {
    background-color: #fff;
    padding: 10px 10px !important;
    &-body {
      display: block !important;
    }
  }
}
