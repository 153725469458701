/* Mixin */
/* Set các giá trị với màn hình tương ứng */
$mobile-width: 480px;
$tablet-width: 768px;
$latop-width: 1024px;
$pc-width: 1650px;

$mobile-media: 'only screen and (min-width: 200px) and (max-width: 480px)';
$tablet-media: 'only screen and (min-width: 481px) and (max-width: 1024px)';
$latop-media: 'only screen and (min-width: 1025px) and (max-width: 1650px)';
$pc-media: 'only screen and (min-width: 1650px) and (max-width: 1920px)';

@mixin responsive_mobile() {
  @media #{$mobile-media} {
    @content;
  }
}

@mixin responsive_tablet() {
  @media #{$tablet-media} {
    @content;
  }
}

@mixin responsive_latop() {
  @media #{$latop-media} {
    @content;
  }
}

@mixin responsive_pc() {
  @media #{$pc-media} {
    @content;
  }
}
