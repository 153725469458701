@import "@styles/responsiveLatop.scss";
@import "@styles/responsiveMobile.scss";
@import "@styles/responsiveTablet.scss";

.homepage {
    margin: 0 10%;
    &-group-input {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        &-ranger {
            width: 300px;
            margin-right: 20px;
        }
        &-search {
            width: 200px;
            margin-right: 20px;
        }
    }
    &-group-card {
        display: flex;
        justify-content: space-between;
        &-item {
            width: calc(30%);
            &-text {
                font-size: 26px;
                font-weight: 700;
                color: #001529;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
    &-group-chart {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        &-bar {
            width: 65%;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
        }
        &-pie {
            width: 30%;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
        }
    }
}

@include responsive_latop {
    .homepage {
        margin: 0 5%;
    }
}

@include responsive_tablet {
    .homepage {
        margin: 0 5%;
    }
}

@include responsive_mobile {
    .homepage {
        margin: 0 0;
    }
}