@import './mixin';

@include responsive_latop {
  .filter {
    background-color: #fff;
    margin: 16px 0;
    padding: 20px 20px;
    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-input {
        display: flex;
        align-items: center;
        width: 100%;
        margin-right: 30px;
        &-btn {
          background-color: #d9d9d9;
          border-radius: 0 !important;
          height: 40px;
          font-size: 15px;
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
        &-input {
          border-radius: 0 !important;
          height: 40px;
          font-size: 15px;
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
          padding-left: 30px;
        }
      }
      &-btn {
        border-radius: 4px !important;
        height: 40px;
      }
    }
    &-value {
      display: flex;
      padding-top: 20px;
      &-item {
        margin-right: 20px;
        &-span {
          font-weight: 600;
        }
        &-icon {
          margin-left: 5px;
          color: #888;
          position: relative;
          top: 2px;
          font-size: 13px;
          &:hover {
            color: red;
          }
        }
      }
    }
  }

  .main {
    overflow-x: hidden;
    &-left {
      width: 70%;
      background-color: #fff;
      padding-top: 30px;
    }
    &-right {
      width: calc(30% - 20px);
      &-wrap {
        width: 100%;
        &-card {
          width: 100%;
          background-color: #fff;
          margin-bottom: 16px;
          &-title {
            border-bottom: 1px solid #ededed;
            margin: 0;
            padding: 16px 20px;
          }
          .avatar-uploader {
            margin: 25px 20px;
            &-1 {
              .ant-upload.ant-upload-select,
              .ant-upload-list-item-container {
                width: 120px !important;
                height: 160px !important;
              }
              .ant-upload-list-item-thumbnail {
                height: 100% !important;
              }
            }
            &-2 {
              width: 100%;
              height: 180px;
              .ant-upload-list.ant-upload-list-picture-car,
              .ant-upload.ant-upload-select,
              .ant-upload-list-item-container {
                width: 100% !important;
                height: 180px !important;
              }
            }
            &-3 {
              width: 90%;
              height: 180px;
              .ant-upload-list.ant-upload-list-picture-car,
              .ant-upload.ant-upload-select,
              .ant-upload-list-item-container {
                width: 90% !important;
                height: 180px !important;
              }
              img {
                height: 180px !important;
                width: auto !important;
              }
            }
          }
        }
      }
    }
    &-add {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      &-form {
        width: 100%;
      }
    }
  }

  .button-submit {
    margin-top: 30px;
    margin-right: 8.4%;
    &-container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
    }
  }

  .action-custom-btn {
    border: 1px solid #1890ff;
    text-align: center;
    color: #1890ff;
    transition: all 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #1890ff;
      color: #fff;
      transition: all 0.15s ease-in-out;
    }
  }

  .main-left-block {
    &-title {
      padding-left: 20px;
      font-size: 16px;
      color: #1890ff;
      font-weight: 600;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
      }
      &-icon {
        font-size: 16px;
        color: #1890ff;
        font-weight: 600;
      }
    }
    &-studyflow {
      border: 1px dashed #888;
      padding: 20px 0;
    }
  }

  .main-qr {
    margin-top: 20px;
    text-align: center;
    background-color: #fff;
    position: relative;
    canvas {
      max-width: 400px;
      max-height: 400px;
    }
    &-overlayout {
      background-color: #f1f0f0cc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      padding-top: 11%;
      font-size: 24px;
      font-weight: 600;
      color: orange;
    }
    &-text {
      font-size: 15px;
      text-align: center;
      margin: 15px 0;
    }
  }

  .dropdown-component {
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &-title {
      font-size: 14px;
      border-bottom: 1px solid #eee;
      font-weight: 600;
      padding: 5px 0;
    }
    &-label {
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 6px;
    }
    &-footer {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .schedual {
    margin: 15px 8%;
    &-header {
      display: flex;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
      &-1 {
        width: 15%;
        text-align: center;
      }
      &-2 {
        width: 10%;
        text-align: center;
      }
      &-3 {
        width: 30%;
        text-align: center;
      }
      &-4 {
        width: 40%;
        text-align: center;
      }
      &-5 {
        width: 5%;
        text-align: center;
      }
    }
    &-body {
      display: flex;
      width: 100%;
      &-1 {
        width: 15%;
      }
      &-2 {
        width: 9.5%;
      }
      &-3 {
        width: 30%;
      }
      &-4 {
        width: 40%;
      }
      &-5 {
        width: 5%;
      }
    }
    &-item {
      border-radius: 0 !important;
    }
    &-btn {
      margin-top: 20px;
      display: flex;
      justify-content: right;
    }
  }

  .pagination-table {
    &-wrap {
      display: flex;
      justify-content: right;
      margin-top: 20px;
      margin-bottom: 50px;
    }
    &-title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .main-left-block-wall {
    border: 1px dashed #bfbfbf;
    margin: 20px 0;
  }
  .upload-tuition {
    .ant-upload.ant-upload-select {
      width: 100% !important;
      height: 280px !important;
    }
  }

  // css cho thẻ input ra giữa
  .ant-picker-input {
    input {
      text-align: center !important;
    }
  }
  .tuition-payment {
    &-image-preview {
      height: 280px;
      width: auto;
    }
    &-text {
      &:hover {
        cursor: pointer;
      }
    }
    .ant-form-item-control-input-content {
      text-align: center;
    }
    #imgMoney {
      img {
        height: 280px !important;
      }
    }
  }
  .tuition-no-payment {
    background-color: #fff;
    margin-top: 5px;
    padding-bottom: 10px;
    text-align: center;
    &-img {
      width: 260px;
    }
    &-text {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .main-right {
    img {
      height: 200px !important;
      width: auto;
    }
  }

  .upload-block-1 {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 150px !important;
      height: 200px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 150px !important;
        margin-left: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 150px !important;
        height: 200px !important;
      }
    }
  }

  .upload-block-2 {
    padding: 5px 10px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 11vw !important;
      height: 6.5vw !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 11vw !important;
        height: 6.5vw !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 100% !important;
        height: 130px !important;
      }
    }
  }

  .upload-block-title {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
  }

  .upload-block-1-add {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 150px !important;
      height: 200px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 210px !important;
        margin-left: -30px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 150px !important;
        height: 200px !important;
      }
    }
  }

  .upload-block-2-add {
    padding: 5px 10px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 11vw !important;
      height: 6.7vw !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        width: 10.9vw !important;
        height: auto !important;
        margin-left: 0px !important;
        margin-top: -42px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 11vw !important;
        height: 6.5vw !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 100% !important;
        height: 130px !important;
      }
    }
  }
  .upload-block-3 {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 185px !important;
      height: 240px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 185px !important;
        margin-left: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 185px !important;
        height: 240px !important;
      }
    }
  }
  .upload-block-3-add {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 185px !important;
      height: 240px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 240px !important;
        margin-left: -29px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 185px !important;
        height: 240px !important;
      }
    }
  }

  .upload-block-4 {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 270px !important;
      height: 170px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 270 !important;
        margin-top: 0px !important;
        margin-left: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 270px !important;
        height: 170px !important;
      }
    }
  }
  .upload-block-4-add {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 270px !important;
      height: 170px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: 265px !important;
        width: auto !important;
        margin-top: -50px !important;
        margin-left: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 270px !important;
        height: 170px !important;
      }
    }
  }

  .upload-block-5 {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 170px !important;
      height: 270px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 170 !important;
        margin-top: 0px !important;
        margin-left: 0px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 170px !important;
        height: 270px !important;
      }
    }
  }

  .upload-block-5-add {
    padding: 10px 30px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 170px !important;
      height: 270px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: auto !important;
        width: 260px !important;
        margin-top: 0px !important;
        margin-left: -40px !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 170px !important;
        height: 270px !important;
      }
    }
  }

  .upload-block-6-add {
    padding: 10px 0px;
    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: 100% !important;
      height: 270px !important;
    }
    .ant-upload-list-item-thumbnail {
      img {
        height: 390px !important;
        width: auto !important;
        margin: 0 auto !important;
        text-align: center !important;
        margin-top: -60px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 0 !important;
      }
    }
    .ant-upload-list-item-image,
    .ant-upload-list-item-done {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        width: 100% !important;
        height: 270px !important;
      }
    }
  }
  .tuition-p {
    &:hover {
      cursor: pointer;
    }
  }
}
