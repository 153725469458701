@import '@styles/mixin';

.min-h-body {
  min-height: calc(100vh - 312px);
}
.h-675 {
  height: 675px;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d1d1d1;
  }
}
.login {
  &-page {
    display: inline-block;
    width: 100vw;
    height: 100vh;
    background-color: #31343d;
  }
  &-form {
    background-color: #3b3d46;
    height: 100%;
    width: 100%;

    input.form-control {
      background-color: #494b54;
      color: white;
    }

    img.logo {
      height: 100px;
    }
    margin: auto;
    margin-top: 100px;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 30px;
    padding-right: 30px;
    width: 400px;
    height: auto;
    max-height: 100vh;
    overflow: hidden !important;
  }
}

.background-tab {
  background-color: #3b3d46;
}

.h-70 {
  height: 70px;
}
.form {
  margin-top: 50px;
}

.relative {
  position: relative;
  &-input {
    width: 100%;
    height: 40px;
  }
  &-icon {
    position: absolute;
    font-size: 20px;
    color: #3b3d46;
    top: 10px;
    right: 20px;
  }
}

.login-form {
  img {
    display: block;
    margin: 0 auto;
  }
}
.text-sm {
  color: #d1d1d1;
}
.button {
  width: 100%;
  height: 38px;
  margin-top: 6px;
  font-weight: 600;
  font-size: 16px;
}

@include responsive_pc {
}

@include responsive_latop {
}

@include responsive_tablet {
}

@include responsive_mobile {
}
